<template>
    <section id="info-list">
      <card>
        <ul>
          <li>{{$t('visitors.facts.mobileVisits.headline')}}</li>
          <li>{{$t('visitors.facts.pagesVisited.headline')}}</li>
          <li>{{$t('visitors.facts.timeSpend.headline')}}</li>
        </ul>
      </card>
    </section>
</template>

<script>
    export default {
        name: "InfoList"
    }
</script>

<style scoped lang="scss">
  ul {
    min-height: 300px;
  }
</style>
